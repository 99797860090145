import { BetterForm } from "@with-cardinal/router-fx";
import plausibleEvent from "../plausible";

export class SignupForm extends BetterForm {
  onSuccess() {
    plausibleEvent("signup-success");
  }

  onError() {
    plausibleEvent("signup-error");
  }
}
