import { Fx } from "@with-cardinal/fx";

export class HamburgerIcon extends Fx {
  attach() {
    this.listen(this, "click", () => this.onHamburgerClick());
    this.listen(document.querySelector("#hamburger-nav"), "click", (evt) =>
      this.onLinkClick(evt)
    );
  }

  onHamburgerClick() {
    const header = document.querySelector("header");

    if (header.dataset.open === "false") {
      header.dataset.open = "true";
      document.body.dataset.noScroll = "true";
    } else {
      header.dataset.open = "false";
      document.body.dataset.noScroll = "false";
    }
  }

  onLinkClick(evt) {
    if (evt.target.tagName === "A") {
      const header = document.querySelector("header");

      header.dataset.open = "false";
      document.body.dataset.noScroll = "false";
    }
  }
}
