import Plausible from "plausible-tracker";

let analyticsDomain;
let enabled = false;
if (window.location.hostname === "www.withcardinal.com") {
  analyticsDomain = "withcardinal.com";
  enabled = true;
} else if (window.location.hostname === "stg2.withcardinal.com") {
  analyticsDomain = "stg2.withcardinal.com";
  enabled = true;
}

const { trackEvent, enableAutoPageviews } = Plausible({
  domain: analyticsDomain,
});

if (enabled) {
  enableAutoPageviews();
}

export default function plausibleEvent(name, options) {
  if (enabled) {
    trackEvent(name, options);
  } else {
    console.log("plausibleEvent", name, options);

    if (options?.callback) {
      options.callback();
    }
  }
}
