import { Fx } from "@with-cardinal/fx";
import { ClientRouter } from "@with-cardinal/client-router";

export class RouterLink extends Fx {
  link;

  attach() {
    this.link = this.querySelector("a");
    this.listen(this.link, "click", (evt) => this.onClick(evt));
  }

  onClick(evt) {
    evt.preventDefault();
    ClientRouter.push(this.link.href);
  }
}
